import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "stylesheets/application.css"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

document.addEventListener('turbolinks:load', () => {

    document.querySelectorAll('[data-copy]').forEach((el) => {
        el.addEventListener('click', async () => {
            try {
                const result = await navigator.clipboard.writeText(el.dataset.copy);

                return true;
            } finally {
                return false;
            }
        });
    });

    document.querySelectorAll('[aria-controls="mobile-menu"]').forEach((el) => {
        el.addEventListener('click', () => {
            document.getElementById('mobile-menu').classList.toggle('hidden');
        });
    });
});
